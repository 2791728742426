import React from "react";
import { useState, useEffect } from "react";
import Sdvjsdfjkasdhjhjh from "./components/Sdvjsdfjkasdhjhjh";
import "./css/New.css";
import "./css/index.css";

function MusicPlayerApp() { 
  const [songs1, setSongs1] = useState(null);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   fetch('http://localhost:3000/api/music')
  //     .then(response => response.json())
  //     .then(data => {
  //       setSongs(data);
  //       setLoading(false);
  //     });
  // }, []);

// console.log(songs);

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch('http://localhost:3000/api/music');
      console.log('Response:', response);

      const data = await response.json();
      
      // Check local storage first
      const songs1 = localStorage.getItem('musicData');
      if (songs1) {
        setSongs1(JSON.parse(songs1));
        return; // Early return to avoid refetching
      } 
      setSongs1(data);
      localStorage.setItem('songs1', JSON.stringify(data));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Only fetch data if musicData is null (avoid refetching)
  //if (!songs) {
   // fetchData();
  //}
}, []);


   useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-7Q9P6SPPYM';        
    script.async = true;
    document.head.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-7Q9P6SPPYM');
  }, []);

  const [songs] = useState([
    {
      title: "Love thy nature",
      artist: "Lyrics: Mr. Muthukumaran Azhagesan, Producer/Director: Mr. Muthukumaran Azhagesan, Google Gemini, Meta AI, Singer/Music: that fantastic AI model/LLM (confidential) . All rights reserved © Muthukumaran Azhagesan https://www.damnittameitai.co It's recommended to listen to this song along with Digital analyzer(below) ON",
      album: "Nature is awesome",
      track: "Praise nature: female",
      year: "2024",
      img_src: "love-the-nature2-Album-cover.png",
      src: "Love_the_nature_female.mp3",
      display_banner: true,
    },
    {
      title: "God is love",
      artist: "Lyrics: Mr. Muthukumaran Azhagesan, Producer/Director: Mr. Muthukumaran Azhagesan, chatGPT, Singer/Music: that fantastic AI model/LLM (confidential) . All rights reserved © Muthukumaran Azhagesan https://www.damnittameitai.co It's recommended to listen to this song along with Digital analyzer(below) ON",
      album: "God",
      track: "God is love!#1",
      year: "2024",
      img_src: "GodisLove.png",
      src: "Gods_version_4___Made_by_Muthukumaran_Azhagesan.mp3",
      display_banner: true,
    },
    {
      title: "God is love, extended version",
      artist: "Lyrics: Mr. Muthukumaran Azhagesan, Producer/Director: Mr. Muthukumaran Azhagesan, chatGPT, Singer/Music: that fantastic AI model/LLM (confidential) . All rights reserved © Muthukumaran Azhagesan https://www.damnittameitai.co It's recommended to listen to this song along with Digital analyzer(below) ON",
      album: "God",
      track: "God is love!#23",
      year: "2024",
      img_src: "GodcontrolsAliens.png",
      src: "Gods-control-aliens-And-aliens-control-humans__Made_by_Muthukumaran_Azhagesan.mp3",
      display_banner: true,
    },                        
    {
      title: "I am on fire!",
      artist: "Lyrics: Mr. Muthukumaran Azhagesan, Producer/Director: Mr. Muthukumaran Azhagesan, chatGPT, Singer/Music: that fantastic AI model/LLM (confidential) . All rights reserved © Muthukumaran Azhagesan https://www.damnittameitai.co It's recommended to listen to this song along with Digital analyzer(below) ON",
      album: "Weekend Thoughts",
      track: "Weekend Thoughts - I'm on fire!",
      year: "2024",
      img_src: "Weekend-Thoughts-Album-Cover.png",
      src: "Weekend-Thoughts__Made_by_Muthukumaran_Azhagesan.wav",
      display_banner: true,
    },   
    {
      title: "New beginnings",
      artist: "Lyrics, Music Producer/Director:Mr. Muthukumaran Azhagesan + Meta Llama3, AI, Google Gemini + a secret AI model/LLM (confidential)",
      album: "Blessed beginnings",
      track: "New beginning Country(fast) tempo",
      year: "2024",
      img_src: "New-Beginnings.png",
      src: "NewBeginnings_Made_by_Muthukumaran_Azhagesan.mp3",
      display_banner: true,
    },
    {
      title: "Victory Song",
      artist: "Lyrics: Mr. Muthukumaran Azhagesan, Singer/Music: that fantastic AI model/LLM (confidential) . All rights reserved © Muthukumaran Azhagesan https://www.damnittameitai.co It's recommended to listen to this song along with Digital analyzer(below) ON",
      album: "Victory Song",
      track: "Victory Song",
      year: "2024",
      img_src: "Victory-song.png",
      src: "Victory-Song.wav",
      display_banner: true,
    },
    {
      title: "Under the moonlight in a forest",
      artist: "Mr. Muthukumaran Azhagesan, Meta AI Llama 3.1 LLM + a fantastic AI model/LLM (confidential) . All rights reserved © Muthukumaran Azhagesan https://www.damnittameitai.co It's recommended to listen to this song along with Digital analyzer(below) ON",
      album: "Whispers Under the Moon",
      track: "Under the moonlight in a forest",
      year: "2024",
      img_src: "Moonlight A Hearts delight - animated version.gif",
      src: "Moonlight2.wav",
      display_banner: true,
    },
    {
      title: "Mars and beyond - Bound to Mars",
      artist: "Mr. Muthukumaran Azhagesan, Google Gemini + a fantastic AI model/LLM (confidential)",
      album: "Mars and beyond",
      track: "Bound to Mars Ultimate",
      year: "2024",
      img_src: "Mars-bound_ultimate1.png",
      src: "Mars-bound_ultimate1.wav",
      display_banner: true,
    },
    {
      title: "Blessed beginnings - King of Opulence",
      artist: "Mr. Muthukumaran Azhagesan, Google Gemini + a fantastic AI model/LLM (confidential)",
      album: "Blessed beginnings",
      track: "King of Opulence",
      year: "2024",
      img_src: "BlessedBeginnings-Album-Cover.png",
      src: "Blessedbeginnings.wav",
      display_banner: true,
    },
    {
      title: "Blessed beginnings - refined: God is love",
      artist: "100% GenAI music, using Google Gemini + a fantastic AI model/LLM (confidential) + Mr. Muthukumaran Azhagesan",
      album: "Blessed beginnings",
      track: "Refined: God is love Fantastico",
      year: "2024",
      img_src: "GodisLoveRenewed_new-Album-Cover.png",
      src: "God is Love renewed.wav",
      display_banner: true,
    },
    {
      title: "Tujhe Dekho To - Dilwale Dulhania Le Jayenge",
      artist: "Singers: Lata Mangeshkar and Kumar Sanu | Music Director: Jatin-Lalit | Composer: Jatin-Lalit | Poets: Anand Bakshi, Jatin Lalit | ",
      album: "Dilwale Dulhania Le Jayenge",
      track: "Tujhe Dekho To",
      year: "1995",
      img_src: "DDLJ-Movie-Album-cover.jpg",
      src: "Tujhe-Dekha-To-Dilwale-Dulhania-Le-Jayenge-320Kbps.mp3",
    },
    {
      title: "Manasum manasum senthaachu",
      artist: "Singers: S.P. Balasubrahmanyam, Sunanda | Music Director: S.A. Rajkumar | Composer: S.A. Rajkumar | Poet: S.A. Rajkumar | ",
      album: "Perumpulli",
      track: "Manasum manasum senthaachi",
      year: "1991",
      img_src: "Perumpulli-Movie-Album-Cover.webp",
      src: "Manasum-manasum-S-P-Balasubrahmanyam-Sunanda.m4a",
    },
    {
      title: "You'Re My Love - Partner",
      artist: "Singers: Shaan, Shweta Pandit, Suzi Q, Earl | Music Directors: Sajid, Wajid | Composers: Sajid, Wajid | Poet: Shabbir Ahmed | ",
      album: "Partner",
      track: "You'Re My Love",
      year: "2007",
      img_src: "Partner-Hindi-2007-Movie-Album-Cover.jpg",
      src: "Youre-My-Love-Partner-320Kbps.mp3",
    },
    {
      title: "Kaalidasan Soorakottai Singakutti",
      artist: "Singers: P. Jayachandran, P. Susheela | Music Director: Ilayaraja | Composer: Ilayaraja | Poet: Vaali | ",
      album: "Soorakottai Singakutti",
      track: "Kaalidasan",
      year: "1983",
      img_src: "Soorakottai-Singakutti-Album-Cover.jpg",
      src: "Kalidasan-Kannadasan.mp3",
    },
    {
      title: "Aahaya Gangai - Dharma Yuddham",
      artist: "Singers: Malaysia Vasudevan, S. Janaki | Music Director: Ilaiyaraaja | Composer: Ilaiyaraaja | Poet: M. G. Vallabhan | ",
      album: "Dharma Yuddham",
      track: "Aahaya Gangai",
      year: "1979",
      img_src: "Aahaya-Gangai-Dharma-Yuddham-Album-Cover.png",
      src: "Aahaya-Gangai-Dharma-Yuddham.mp3",
    },
    {
      title: "Rosappu Chinna Rosappu - Suryavamsam",
      artist: "Singers: Hariharan | Music Director: SA Rajkumar | Composer: SA Rajkumar | Poet: R.Ravishankar | ",
      album: "Suryavamsam",
      track: "Rosappu Chinna Rosappu",
      year: "1983",
      img_src: "Suryavamsam-Album-Cover.jpg",
      src: "Suryavamsam-Rosapoo-Chinna-rosappu.mp3",
    },
    {
      title: "Oriental Asian Chinese : Nature's Symphony. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "Oriental Asian Chinese, Nature's Symphony, AI & ML GenAI",
      track: "Nature's Symphony",
      year: "2024",
      img_src: "Nature's-Symphony-Album-Cover.png",
      src: "Nature's-Symphony-Nature-is-Precious.mp3",
      display_banner: true,
    },
    {
      title: "Fast Dancing Electronic Beats : The Climb III. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "The Climb - III, AI & ML GenAI",
      track: "The Climb - III",
      year: "2024",
      img_src: "The-Climb-III.jpg",
      src: "ClimbIII.mp3",
      display_banner: true,
    },
    {
      title: "Surf-Rock Electronic Intense : Oceanic Nights. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "Oceanic Nights, AI & ML GenAI",
      track: "Oceanic Nights Female",
      year: "2024",
      img_src: "Oceanic-Nights-Album-Cover.png",
      src: "Oceanic-Nights-Female-Voice.mp3",
      display_banner: true,
    },
    {
      title: "Electronic Beats with Hip-hop : Green Trees Everywhere. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "Green Trees Everywhere, AI & ML GenAI",
      track: "Green Trees Everywhere",
      year: "2024",
      img_src: "Green-Trees-Everywhere-Album-Cover.png",
      src: "Green-Trees-Everywhere.mp3",
      display_banner: true,
    },
    {
      title: "Surf-Rock Electronic Intense : Oceanic Nights. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "Oceanic Nights, AI & ML GenAI",
      track: "Oceanic Nights Male",
      year: "2024",
      img_src: "Oceanic-Nights2-Album-Cover.png",
      src: "Oceanic-Nights-Male-Voice.mp3",
      display_banner: true,
    },
    {
      title: "Fast Country : World Traveller. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "World Traveller, AI & ML GenAI",
      track: "World Traveller Female",
      year: "2024",
      img_src: "World_traveller_music_album_cover_Muthukumaran_Azhagesan.png",
      src: "World-Traveller-latest-Female-Voice.mp3",
      display_banner: true,
    },
    {
      title: "Fast Country : World Traveller. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "World Traveller, AI & ML GenAI",
      track: "World Traveller Male",
      year: "2024",
      img_src: "World_traveller_music_album_cover_Muthukumaran_Azhagesan.png",
      src: "World-Traveller-latest-Male-Voice.mp3",
      display_banner: true,
    },
    {
      title: "Hip-hop : Climb to the Top Fantastic. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "Climb to the Top Fantastic, AI & ML GenAI",
      track: "Climb to the Top Fantastic",
      year: "2024",
      img_src: "Climb-to-the-Top-Fantastic.png",
      src: "Climb-to-the-Top-Fantastic.mp3",
      display_banner: true,
    },
    {
      title: "intense electronic dramatic : The Climb I. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "The Climb I, AI & ML GenAI",
      track: "The Climb I",
      year: "2024",
      img_src: "The-Climb-I.jpg",
      src: "The-Climb-Mesmerizing.mp3",
      display_banner: true,
    },
    {
      title: "Hip-hop : Climb to the Top Great. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "Climb to the Top Great, AI & ML GenAI",
      track: "Climb to the Top Great",
      year: "2024",
      img_src: "Climb-to-the-Top-Great.png",
      src: "Climb-to-the-Top-Great.mp3",
      display_banner: true,
    },
    {
      title: "intense electronic dramatic : The Climb II. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "The Climb II, AI & ML GenAI",
      track: "The Climb II",
      year: "2024",
      img_src: "The-Climb-II.jpg",
      src: "The-Climb-Clearly-Awesome.mp3",
      display_banner: true,
    },
    {
      title: "Jazz: On the Road to Success V1. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini + another LLM(confidential), & Mr. Muthukumaran Azhagesan",
      album: "AI & ML GenAI Music",
      track: "On the Road to Success V1",
      year: "2024",
      img_src: "On-the-Road-to-Success-Version1.png",
      src: "On-the-Road-to-Success-Version1.mp3",
      display_banner: true,
    }, 
    {
      title: "Rock: Edge of Shadows fantastic. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini + another LLM(confidential), & Mr. Muthukumaran Azhagesan",
      album: "AI & ML GenAI Music",
      track: "Edge of Shadows fantastic",
      year: "2024",
      img_src: "Edge-of-Shadows-fantastic-Muthukumaran-Azhagesan.png",
      src: "Edge-of-Shadows-fantastic-Muthukumaran-Azhagesan.mp3",
      display_banner: true,
    },
    { 
    title: "Jazz: On the Road to Success V2. Made by Muthukumaran Azhagesan",
    artist: "Google Gemini + another LLM(confidential), & Mr. Muthukumaran Azhagesan",
    album: "AI & ML GenAI Music",
    track: "On the Road to Success V2",
    year: "2024",
    img_src: "On-the-Road-to-Success-Version2.png",
    src: "On-the-Road-to-Success-Version2.mp3",
  },  
    {
      title: "Rock: Live life in excess fantastic. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "AI & ML GenAI",
      track: "Live life in excess fantastic",
      year: "2024",
      img_src: "Live-life-in-excess-Muthukumaran-Azhagesan-made.png",
      src: "live-life-excess.mp3",
      display_banner: true,
    },  
    {
      title: "Rock: Edge of Shadows superb. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini + another LLM(confidential), & Mr. Muthukumaran Azhagesan",
      album: "AI & ML GenAI Music",
      track: "Edge of Shadows superb",
      year: "2024",
      img_src: "Edge-of-Shadows-superb-Muthukumaran-Azhagesan.png",
      src: "Edge-of-Shadows-superb-Muthukumaran-Azhagesan.mp3",
      display_banner: true,
    },    
    {
      title: "Rock: Rags to Riches : Google Gemini + another LLM(confidential). Made by Muthukumaran Azhagesan",
      artist: "Google Gemini + another LLM(confidential), & Mr. Muthukumaran Azhagesan",
      album: "AI & ML GenAI Music",
      track: "Rags to Riches - 1",
      year: "2024",
      img_src: "rags-to-Riches-superb.png",
      src: "rags-to-Riches-superb.mp3",
      display_banner: true,
    },
    {
      title: "Rock: Rags to Riches : Google Gemini + another LLM(confidential). Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "AI & ML GenAI",
      track: "Rags to Riches - 2",
      year: "2024",
      img_src: "rags-to-Riches-fantastic.png",
      src: "rags-to-Riches-fantastic.mp3",
      display_banner: true,
    },  
    {
      title: "Soulful downtempo blues: Rainy day love : Google Gemini + another LLM(confidential). Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "AI & ML GenAI",
      track: "Rainy day love",
      year: "2024",
      img_src: "Rainy-day-love.png",
      src: "Rainy-Day-Love.mp3",
      display_banner: true,
    },  
    {
      title: "Rock Music: Dancing in the Sunlight : Google Gemini + another confidential LLM. Made by Muthukumaran Azhagesan",
      artist: "Google Gemini+ another LLM (confidential) & Mr. Muthukumaran Azhagesan",
      album: "AI & ML GenAI",
      track: "Dancing in the Sunlight",
      year: "2024",
      img_src: "Dancing-in-the-Sunlight.png",
      src: "Dancing-in-the-Sunlight.mp3",
      display_banner: true,
    },      
    {
      title: "Get your toes tapping - Bluegrass Country - Google Music LM & Muthukumaran Azhagesan",
      artist: "Google Music LM & Muthukumaran Azhagesan",
      album: "AI & ML GenAI",
      track: "Get your toes tapping",
      year: "2024",
      img_src: "Gemini_Generated_Image3.jpg",
      src: "music_fx_bluegrass_breakdown_get_your_toes_tappin_wi_pleasing-one.mp3",
      display_banner: true,
    },  
    {
      title: "Anthemic - Country Music - Google Music LM & Muthukumaran Azhagesan",
      artist: "Google Music LM & Muthukumaran Azhagesan",
      album: "AI & ML GenAI",
      track: "Anthemic",
      year: "2024",
      img_src: "Gemini_Generated_Image2.png",
      src: "music_fx_anthemic_singalong_channel_the_energy_of_a_s_good.mp3",
      display_banner: true,
    },
    {
      title: "Rhythm Rock - Google Music LM & Muthukumaran Azhagesan",
      artist: "Google Music LM & Muthukumaran Azhagesan",
      album: "AI & ML GenAI",
      track: "11",
      year: "2024",
      img_src: "Gemini_Generated_Image1.jpg",
      src: "music_fx_start_with_a_driving_rhythm_section_distorte.mp3",
      display_banner: true,
    },
    {
      title: "High energy Punk Rock - Google Music LM & Muthukumaran Azhagesan",
      artist: "Google Music LM & Muthukumaran Azhagesan",
      album: "AI & ML GenAI",
      track: "12",
      year: "2024",
      img_src: "DALL·E.2024-01-21.png",
      src: "music_fx_highenergy_punk_rock__channel_the_raw_energ-chosen.mp3",
      display_banner: true,
    },  
    {
      title: "Manzil (1979) - Rimjhim Gire Sawan (Duet)",
      artist: "Singers: Lata Mangeshkar, Kishore Kumar | Music Director: R. D. Burman | Composer: R. D. Burman | Poet: 	Yogesh | ",
      album: "Manzil",
      track: "Rimjhim Gire Sawan (Duet)",
      year: "1979",
      img_src: "Rimjhim-Gire-Sawan-Manzil(1977).jpg",
      src: "Rimjhim-Gire-Sawan-(Duet)_320kbps.mp3",
    },
    {
      title: "Kati Patang (1970) - Yeh Jo Mohabbat Hai",
      artist: "Singer: Kazi Arindam | Music Director: R. D. Burman   | Composer: R. D. Burman | Poet: 	Anand Bakshi | ",
      album: "Kati Patang",
      track: "Yeh Jo Mohabbat Hai",
      year: "1970",
      img_src: "Kati_Patang-Album-Cover.jpg",
      src: "Yeh-Jo-Mohabbat-Hai.mp3",
    },
    {
      title: "Osthi - Kalasala Kalasala",
      artist: "L.R.Easwari, T.Rajendar, Solar Sai | Music Director: Thaman S | Composer:  Thaman S | Poets: Vaali, Yugabharathi & Silambarasan | ",
      album: "Osthi",
      track: "Kalasala Kalasala",
      year: "2011",
      img_src: "Osthi-Album-Cover.jpg",
      src: "Kalasala-Kalasala.mp3",
    },
    {
      title: "Ellam Inba Mayam - Barla Barla",
      artist: "Singers:	Malaysia Vasudevan | Music Director: Ilaiyaraja | Composer:  Ilaiyaraja | Poet: Panju Arunachalam | ",
      album: "Ellam Inba Mayam",
      track: "Barla Barla",
      year: "1981",
      img_src: "Yellam-Inba-Mayam-Music-Album-Cover.png",
      src: "Barla-Barla.mp3",
    },  
    {
      title: "Ullaasa Paravaihal - Dheiveeha Raaham",
      artist: "Singers:	Jency, Vani Jairam | Music Director: Ilaiyaraja | Composer:  Ilaiyaraja | Poet: Rajasri | ",
      album: "Ullaasa Paravaihal",
      track: "Dheiveeha Raaham",
      year: "1980",
      img_src: "Ullaasa-Paravaihal.png",
      src: "Deiveeha-Raaham.mp3",
    },  
    {
      title: "Anandha Kummi - Oru kili uruguthu",
      artist: "Singers: S. P. Balasubramaniam, S. Janaki | Music Director: Ilaiyaraja | Composer:  Ilaiyaraja | Poet: Vairamuthu | ",
      album: "Anandha Kummi",
      track: "Oru kili uruguthu",
      year: "1983",
      img_src: "Anandha-Kummi-Album-Cover.jpg",
      src: "Oru-Kili-Uruguthu.mp3",
    },  
    {
      title: "Music score generated with AI ML DL",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "AI_Test_Kitchen_upbeat_music_fantastic1",
      year: "2023",
      img_src: "Picture_from_Mars5.png",
      src: "AI_Test_Kitchen_traditional_asian_music6.mp3",
      display_banner: true,
    },  
    {
      title: "Jalabulajangu",
      artist: "Singer: Anirudh Ravichander | Music Director & Vocals: Anirudh Ravichander | Composer: Anirudh Ravichander | Poet: Rokesh | ",
      album: "Don - Jalabulajungu",
      track: "Don",
      year: "2021",
      img_src: "Don-Movie-Album-Cover.jpg",
      src: "Don-Jalabula-Jangu---Anirudh-Ravichander.mp3",
    },   
    {
      title: "Anandha Kummi - Oh-Vennilaave",
      artist: "Singers: S. P. Balasubramaniam, S. Janaki | Music Director: Ilaiyaraja | Composer:  Ilaiyaraja | Poet: Vairamuthu | ",
      album: "Anandha Kummi",
      track: "Oh Vennilaave",
      year: "1983",
      img_src: "Anandha-Kummi-Album-Cover.jpg",
      src: "Oh-Vennilaave.mp3",
    },  
   { 
      title: "Music score generated with AI ML DL",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "AI_Test_Kitchen_upbeat_music7",
      year: "2023",
      img_src: "Picture_from_Mars2.png",
      src: "AI_Test_Kitchen_upbeat_music7.mp3",
      display_banner: true,
    },  
    {
      title: "Anandha Kummi - Oomai-Nenjin",
      artist: "Singers: S. P. Balasubramaniam, S. Janaki | Music Director: Ilaiyaraja | Composer:  Ilaiyaraja | Poet: Vairamuthu | ",
      album: "Anandha Kummi",
      track: "Oomai Nenjin",
      year: "1983",
      img_src: "Anandha-Kummi-Album-Cover.jpg",
      src: "Oomai-Nenjin.mp3",
    },  
    {
      title: "Music score generated with AI ML DL",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "AI_Test_Kitchen_upbeat_music8",
      year: "2023",
      img_src: "Picture_from_Mars3.png",
      src: "AI_Test_Kitchen_generate_a_music_that_captures_the_excitement (11)_real_diamond.mp3",
      display_banner: true,
    },  
    {
      title: "Anandha Kummi - Oru-Kili-Urukuthu-Version2",
      artist: "Singers: S. Janaki, S. P. Shailaja  | Music Director: Ilaiyaraja | Composer:  Ilaiyaraja | Poet: Vairamuthu | ",
      album: "Anandha Kummi",
      track: "Oru-Kili-Urukuthu-Version2",
      year: "1983",
      img_src: "Anandha-Kummi-Album-Cover.jpg",
      src: "Oru-Kili-Urukuthu-Version2.mp3",
    },  
 {
      title: "Music score generated with AI ML DL",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "AI_Test_Kitchen_upbeat_music_fantastic7",
      year: "2023",
      img_src: "Picture_from_Mars4.png",
      src: "AI_Test_Kitchen_upbeat_music_fantastic7.mp3",
      display_banner: true,
    },  
 {
      title: "Music score generated with AI ML DL",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "AI_Test_Kitchen_upbeat_music_fantastic2",
      year: "2023",
      img_src: "Picture_from_Mars6.png",
      src: "AI_Test_Kitchen_upbeat_music_fantastic2.mp3",
      display_banner: true,
    },  
 {
      title: "Music score generated with AI ML DL",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "AI_Test_Kitchen_upbeat_music_fantastic3",
      year: "2023",
      img_src: "Picture_from_Mars7.png",
      src: "AI_Test_Kitchen_upbeat_music_fantastic3.mp3",
      display_banner: true,
    },  
 {
      title: "Music score generated with AI ML DL",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "AI_Test_Kitchen_upbeat_music_fantastic4",
      year: "2023",
      img_src: "Picture_from_Mars8.png",
      src: "AI_Test_Kitchen_upbeat_music_fantastic4.mp3",
      display_banner: true,
    },  
 {
      title: "Music score generated with AI ML DL",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "AI_Test_Kitchen_upbeat_music_fantastic5",
      year: "2023",
      img_src: "Planet_Adjacent_to_Mars1.png",
      src: "AI_Test_Kitchen_upbeat_music_fantastic5.mp3",
      display_banner: true,
    },  
 {
      title: "Music score generated with AI ML DL",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "AI_Test_Kitchen_upbeat_music_fantastic6",
      year: "2023",
      img_src: "Planet_Adjacent_to_Mars2.png",
      src: "AI_Test_Kitchen_upbeat_music_fantastic6.mp3",
      display_banner: true,
    },  
 {
      title: "Music score generated with AI ML DL",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "AI_Test_Kitchen_upbeat_music_with_instruments3",
      year: "2023",
      img_src: "Planet_Adjacent_to_Mars2.png",
      src: "AI_Test_Kitchen_upbeat_music_with_instruments3.mp3",
      display_banner: true,
    },  
 {
      title: "Music score generated with AI ML DL",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "AI_Test_Kitchen_upbeat_music_with_instruments1",
      year: "2023",
      img_src: "Planet_Adjacent_to_Mars3.png",
      src: "AI_Test_Kitchen_generate_a_music_that_captures_the_excitement(3)_diamond.mp3",
      display_banner: true,
    },  
 {
      title: "Music score generated with AI ML DL",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "AI_Test_Kitchen_upbeat_music_with_instruments2",
      year: "2023",
      img_src: "Planet_Adjacent_to_Mars4.png",
      src: "AI_Test_Kitchen_romantic_ballad1.mp3",
      display_banner: true,
    },  

    {
      title: "Afghan Jalebi (Ya Baba)",
      artist: "Singer: Asrar(Syed Asrar Shah) | Music Director: Pritam | Composer: Pritam | Poets: Amitabh Bhattacharya, Kausir Munir | Mix & Mastered By: Eric Pillai, FUTURE SOUND OF BOMBAY + Mix & Mastered By: Shadab Rayeen @ NEW EDGE STUDIOS |",
      album: "Phantom",
      track: "Afghan Jalebi (Ya Baba)",
      year: "2015",
      img_src: "Phantom-Movie-Album-Cover.jfif",
      src: "Afghan-Jalebi-(Ya-Baba)---Phantom-320-Kbps.mp3",
    },  
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "listen5",
      year: "2023",
      img_src: "IMG_1420.JPG",
      src: "thoughtful and romantic ❤️.mp3",
      display_banner: true,
    },  
    {
      title: "Extraordinary Music by AI and ML",
      artist: "| Music made by: Muthukumaran Azhagesan & Google MusicLM |",
      album: "AI & ML Generated Music",
      track: "AI & ML Generated Music",
      year: "2023",
      img_src: "futuristic-automobile.png",
      src: "Heavenly-Music-score-Google-MusicLM_plus_Muthukumaran_Azhagesan.mp3",
      display_banner: true,
    },   
    {
      title: "Gupt Gupt - Title",
      artist: "Singers: Kavita Krishnamurthy, Hema Sardesai, Chetan | Music Director: Viju Shah | Composer: Viju Shah | Poet: Anand Bakshi | ",
      album: "Gupt",
      track: "Gupt-Gupt-Title-Gupt",
      year: "1997",
      img_src: "Gupt-Movie-Album-Cover.jpg",
      src: "Gupt-Gupt-Title-Gupt-320-Kbps.mp3",
    },   
    {
      title: "Arabic Kuthu",
      artist: "Singers: Anirudh Ravichander & Jonita Gandhi | Music Director: Anirudh Ravichander | Composer: Anirudh Ravichander | Poet: Sivakarthikeyan | ",
      album: "Beast",
      track: "Arabic Kuthu | Halamithi Habibo",
      year: "2022",
      img_src: "Arabic-Kuthu-Song-Album-Cover.jfif",
      src: "Arabic-Kuthu-Beast-320-Kbps.mp3",
    },      
    {
      title: "Housefull 4",
      artist: "Singers: Sohail Sen, Altamash Faridi, Jyotica Tangri | Music Director: Sohail Sen | Composer: 	Sohail Sen | Poet: Sameer Anjaan | ",
      album: "Housefull 4",
      track: "Housefull 4 | EK CHUMMA",
      year: "2019",
      img_src: "Housefull4-Album-Cover.jpg",
      src: "Ek-Chumma-Housefull4-320Kbps.mp3",
    },   
    {
      title: "Akhiyon Se Goli Maare",
      artist: "Singers: Alka Yagnik, Kumar Sanu | Music Directors: Anand-Milind | Composers: Anand-Milind | Poet: Sameer | ",
      album: "Akhiyon Se Goli Maare",
      track: "Saton Janam Mein Tere",
      year: "1994",
      img_src: "Akhiyon-Se-Goli-Maare-Album-Cover.jpg",
      src: "Ye-Ishq-Ka-Jadu-Hai.mp3",
    },   
    {
      title: "Saton Janam Mein Tere",
      artist: "Singers: Alka Yagnik, Kumar Sanu | Music Directors: Nadeem Shravan | Composers: Nadeem Shravan | Poet: Sameer | ",
      album: "Dilwale",
      track: "Saton Janam Mein Tere",
      year: "1994",
      img_src: "Dilwale-Album-Cover.jpg",
      src: "Saaton-Janam-Main-Tere_320kbps.mp3",
    },   
    {
      title: "Tu Cheez Badi Hai Mast Mast",
      artist: "Singers:  Udit Narayan , Kavita Krishnamurthy | Music Director: Viju Shah | Composer: Viju Shah | Poet: Anand Bakshi | ",
      album: "Mohra",
      track: "Tu Cheez Badi Hai Mast Mast",
      year: "1994",
      img_src: "Mohra-Album-Cover.jpg",
      src: "Tu-Cheez-Badi-Hai-Mast-Mast.mp3",
    },           
    {
      title: "Aanakkeduppathu ponnunde dhanam dolby audio",
      artist: "Singer:  KJ Yesudas | Music Director: Raveendran | Composer: Raveendran | Poet: PK Gopi | ",
      album: "Dhanam",
      track: "Aanakkeduppathu ponnunde",
      year: "1991",
      img_src: "Dhanam-Movie-Album-cover.png",
      src: "Aanakkeduppathu-ponnunde-dhanam-dolby-audio.mp3",
    }, 
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Meta/Facebook MusicGen and Muthukumaran Azhagesan",
      album: "Generative AI Music - Emotional Amazing",
      track: "Movie scenes",
      year: "2023",
      img_src: "IMG_9558.JPG",
      src: "Pleasant-Music.mp3",
      display_banner: true,
    },
    {
      title: "Chori Chori Dil Tera",
      artist: "Singers:  Kumar Sanu, Sujata Goswami | Music Director: Anu Malik | Composer: Anu Malik | Poets: Zameer Kazmi, Deepak Choudhary | ",
      album: "Phool Aur Angaar",
      track: "Chori Chori Dil Tera",
      year: "1993",
      img_src: "Phool-Aur-Angaar-Album-Cover.png",
      src: "Chori-Chori-Dil-Tera-Phool-Aur-Angaar-320Kbps.mp3",
    },    
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Meta/Facebook MusicGen and Muthukumaran Azhagesan",
      album: "Generative AI Music - Emotional Amazing",
      track: "Movie scenes",
      year: "2023",
      img_src: "_597aa9e7-b796-404d-8efe-31a12fd8a7f1.jfif",
      src: "emotional-amazing.mp3",
      display_banner: true,
    },   
    {
      title: "Duniya Haseenon Ka Mela - Gupt",
      artist: "Singers: Udit Narayan, Sunita Rao | Music Director: Viju Shah | Composer: Viju Shah | Poet: Anand Bakshi | ",
      album: "Gupt",
      track: "Duniya Haseenon Ka Mela - Gupt",
      year: "1997",
      img_src: "Gupt-Movie-Album-Cover.jpg",
      src: "Duniya-Haseenon-Ka-Mela-Gupt-320Kbps.mp3",
    },     
    {
      title: "Soora Thenga Adraa Adraa",
      artist: "Singer:  Tippu | Music Director: Vidyasagar | Composer: Vidyasagar | Poet: Na. Muthu Kumar | ",
      album: "Gilli",
      track: "Soora Thenga Adraa Adraa",
      year: "2004",         
      img_src: "Gillie-Album-Cover.jpg",
      src: "Music-By-Music-Director-Vidyasagar-Movie-Gilli-Version-2.mp3",
    },    
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Meta/Facebook MusicGen and Muthukumaran Azhagesan",
      album: "Generative AI Music - Pumping and Rich Music",
      track: "Movie scenes",
      year: "2023",
      img_src: "_a9e542da-613f-4101-8f17-636cc8a685e9.jfif",
      src: "pumping-and-rich-music.mp3",
      display_banner: true,
    },   
    {
      title: "Gori Tera Gaon Bada Pyara",
      artist: "Singer: K.J.Yesudas |  Music Director: Ravindra Jain | Poet: Ravindra Jain | ",
      album: "Chitchor",
      track: "Gori Tera Gaon Bada Pyara",
      year: "1976",
      img_src: "Chitchor-Album-cover.jpg",
      src: "Gori-Tera-Gaon-Bada-Pyara---Chitchor-Amol-Palekar-Zarina-Wahab-K.J.Yesudas.mp3",
    },   
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "listen1",
      year: "2023",
      img_src: "IMG_1421.JPG",
      src: "22.tmpvc1bluio.4star.mp3",
      display_banner: true,
    },
    {
      title: "O Meri Zohrajabeen - Phir Hera Pheri 320 Kbps",
      artist: "Singers: Rakesh Upadhyay, Shaan, Sonu Nigam |  Music Director: Himesh Reshammiya |  Poet: Sameer | ",
      album: "Phir Hera Pheri",
      track: "O Meri Zohrajabeen",
      year: "2006",
      img_src: "Phir-Hera-Pheri-Album.jpg",
      src: "O-Meri-Zohrajabeen---Phir-Hera-Pheri-320-Kbps.mp3",
    },
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "listen2",
      year: "2023",
      img_src: "IMG_1425.JPG",
      src: "23.tmpoq6dcj25.mp3",
      display_banner: true,
    },
    {
      title: "Suno-Miya-Suno-320-Kbps",
      artist: "Singers: Sadhana Sargam, Sushma Shrestha (Poornima), Udit Narayan |  Music Director: Anand Raj Anand | Composer: Anand Raj Anand | Poet: Dev Kohli | ",
      album: "Kyo Kii Main Jhuth Nahin Bolta",
      track: "Suno Miya Suno Miya",
      year: "2023",
      img_src: "Kyo-Kii-Main-Jhuth-Nahin-Bolta-Album-Cover.png",
      src: "Suno-Miya-Suno-320-Kbps.mp3",
    },    
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "listen3",
      year: "2023",
      img_src: "IMG_1426.JPG",
      src: "24.tmpf1cmvptb.mp3",
      display_banner: true,
    },
    {
      title: "Jotheyali Jothe Jotheyali",
      artist: "Singers: S.P. Balasubrahmanyam, S. Janaki |  Music Director: Ilaiyaraaja | Composer: Ilaiyaraaja | Poet: Chi. Udayashankar | ",
      album: "Geetha",
      track: "Jotheyali Jothe Jotheyali",
      year: "1981",
      img_src: "Geetha-Movie-Album-Cover.png",
      src: "Jotheyali-S.P.Balasubrahmanyam-S.Janaki.m4a",
    },
    {
      title: "Adi Aathadi",
      artist: "Singer: S. Janaki |  Music Director: Ilaiyaraaja | Composer: Ilaiyaraaja | Poets: Vairamuthu & Gangai Amaran | ",
      album: "Kadalora Kavithaigal",
      track: "Kadalora Kavithaigal - Adi Aathadi",
      year: "1986",
      img_src: "Kadalora-Kavithaigal-Album-Cover.png",
      src: "Adi-Aathadi.mp3",
    },
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "listen4",
      year: "2023",
      img_src: "IMG_1427.JPG",
      src: "25.tmpwetu7qc4.mp3",
      display_banner: true,
    },   
    {
      title: "Style - Thee Thee 320 Kbps",
      artist: "Singers: Blaaze, Tanvi, Ravi Rags Khote, Suresh Peters | Music Director: A.R.Rahman |  Poet: Pa.Vijay | ",
      album: "Sivaji",
      track: "Style - Thee Thee",
      year: "2007",
      img_src: "Sivaji-the-Boss-poster-500x500.jpg",
      src: "Style---Thee-Thee.mp3",
    },        
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "listen5",
      year: "2023",
      img_src: "IMG-1474.JPG",
      src: "thoughtful and romantic ❤️.mp3",
      display_banner: true,
    },    
    {
      title: "Goopy Gyne Bagha Byne - Dekho Re",
      artist: "Singer: Anup Ghosal |  Music Director: Satyajit Ray | Composer: Satyajit Ray | Poet: Satyajit Ray | ",
      album: "Goopy Gyne Bagha Byne",
      track: "listen3",
      year: "1969",
      img_src: "GOOPI_GYNE_BAGHA_BYNE_-_DVD_COVER.jpg",
      src: "Goopy-Gyne-Bagha-Dekho-re-Nayan-Mele.mp3",
    },
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "listen4",
      year: "2023",
      img_src: "IMG-1473.JPG",
      src: "25.tmpwetu7qc4.mp3",
      display_banner: true,
    },   
    {
      title: "Anveshana - Keeravani",
      artist: "Singer: S.P. Balasubrahmanyam | Music Director: Ilaiyaraaja | Composer: Ilaiyaraaja | Poet: Veturi Sundararama Murthy | ",
      album: "Anveshana - Keeravani",
      track: "Keeravani",
      year: "1985",
      img_src: "Anveshana-Album-cover.jpg",
      src: "Keeravani-S.P.Balasubrahmanyam-S.Janaki.m4a",
    },
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "Jazz",
      year: "2023",
      img_src: "Jazz-catbird-image.png",
      src: "Jazz-AI-Generated.mp3",
      display_banner: true,
    },
    {
      title: "Ki Banu Duniya Da",
      artist: "Singers: Gurdas Maan , Diljit Dosanjh | Music Producer: Jatinder Shah | Composer: Gurdas Maan | Poet: Gurdas Maan | ",
      album: "Ki Banu Duniya Da",
      track: "Ki Banu Duniya Da",
      year: "2015",
      img_src: "Ki-Banu-Duniya-Da-Album-Cover-2.png",
      src: "Ki-Banu-Duniya-Da.mp3",
    },
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Google Music LM and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "Music score generated with AI ML DL for movie scenes",
      year: "2023",
      img_src: "Music-score1-AI-generated-image.jpg",
      src: "Music-score1-AI-generated.mp3",
      display_banner: true,
    },
    {
      title: "Khaled Didi",
      artist: "Singer: Khaled | ",
      album: "Khaled",
      track: "Didi",
      year: "1991",
      img_src: "Khaled-Album-Cover.jpg",
      src: "Didi_Khaled.mp3",
    },
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Meta/Facebook MusicGen and Muthukumaran Azhagesan",
      album: "Generative AI Music",
      track: "Movie scenes",
      year: "2023",
      img_src: "Generative-image-1.png",
      src: "Music-as-per-inputted-Movie-scenes.mp3",
      display_banner: true,
    },   
    {
      title: "Thakita-Thadhimi",
      artist: "Singer: S.P. Balasubrahmanyam | Music Director: Ilaiyaraaja | Composer: Ilaiyaraaja | Poet: Vairamuthu | ",
      album: "Salangai Oli",
      track: "Salangai Oli - Thakita-Thadhimi",
      year: "1983",
      img_src: "Salangai-Oli-Album-Cover.jfif",
      src: "Thakita-Thadhimi.mp3",
    },
    {
      title: "Music score generated with AI ML DL for movie scenes",
      artist: "Meta/Facebook MusicGen and Muthukumaran Azhagesan",
      album: "Generative AI Music - earthy-tones_environmentally-conscious_harmonic_breezy_easygoing_organic-instrumentation_gentle-grooves",
      track: "Movie scenes",
      year: "2023",
      img_src: "_fb62c830-841d-454f-b4c1-c9387d31e663.jfif",
      src: "earthy-tones_environmentally-conscious_harmonic_breezy_easygoing_organic-instrumentation_gentle-grooves.mp3",
      display_banner: true,
    },  
    {
      title: "Dola Dola Dola",
      artist: "Singers:  Srinivas, Swarnalatha | Music Director: A.R. Rahman | Composer: A.R. Rahman | Poet: Mehboob Alam Kotwal | ",
      album: "Dil Hi Dile Mein",
      track: "Dola Dola Dola",
      year: "2000",
      img_src: "Dil-Hi-Dil-Mein-Album-Cover.jpg",
      src: "Dola-Dola-Dil-Hi-Dil-Mein.mp3",
    },    
  ]);

  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [nextSongIndex, setNextSongIndex] = useState(currentSongIndex + 1);
  
  useEffect(() => { 
    setNextSongIndex(() => {
      if (currentSongIndex + 1 > songs.length - 1) {
        return 0;
      } else {
        return currentSongIndex + 1;
      }
    }); 
  }, [currentSongIndex, songs.length]);
  
  return (
    <div className="App music-body dark:body_dark"> 
    { <div>{songs ? (
      <Sdvjsdfjkasdhjhjh
        currentSongIndex={currentSongIndex}
        setCurrentSongIndex={setCurrentSongIndex}
        nextSongIndex={nextSongIndex}
        songs={songs}
      />    ) : (
        <p>Loading music data...</p>
      )}              
    </div> }
    </div>
  );
}

export default MusicPlayerApp;
