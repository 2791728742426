import React, { useState } from "react";
import lotteryPredictionLogo from './images/lottery_prediction.png';
export default function LotteryPrediction() {
  const [collapse, setCollapse] = useState(true);

  const handleClick = () => {
    setCollapse(!collapse);
  };

  const [expandedDivId, setExpandedDivId] = useState(null);
  // const handleDivClick = (divId) => {
  //   setExpandedDivId(divId === expandedDivId ? null : divId);
  // };

  return (
    <div className="flex w-auto h-auto items-center bg-white dark:bg-gray-600 dark:text-white rounded-lg overflow-visible shadow-2xl hover:shadow-lg dark:hover:shadow-slate-50 ui-widget-content" onClick={handleClick}>
    <div className="justify-items-start ui-widget-content">        
          <img 
            className="w-36 h-auto object-contain"
            src={lotteryPredictionLogo}
            alt="Lottery<br/>Prediction"
          />
          <b className="text-left text-nowrap">Predicting Lottery</b> 
      </div>
      <div
        className="flex w-auto h-auto items-center mx-0.5 bg-white  dark:bg-gray-600 dark:text-white rounded-lg overflow-visible shadow-2xl hover:shadow-lg dark:hover:shadow-slate-50  ui-widget-content"
        onClick={handleClick}>
      </div> 
          
      {/* <div className="flex w-auto h-auto items-center mx-0.5 bg-white  dark:bg-gray-600 dark:text-white rounded-lg overflow-visible shadow-2xl hover:shadow-lg dark:hover:shadow-slate-50  ui-widget-content" onClick={handleClick}><b className="text-left">Lottery<br/> Prediction</b></div> */}
      <div className="dark:bg-gray-600 dark:text-white">
      <div className={`dark:bg-gray-600 dark:text-white border-none w-${expandedDivId === 'div1' ? 'full' : '3/4'}  ${collapse ? 'collapsed' : ''}`}>        
      <div className="h-7 w-auto">&nbsp;</div>
      <div className="text-justify p-4 text-xs">
      <br />
      <p>
        <b>Objective:&nbsp;&nbsp;&nbsp;</b>Predict lottery numbers.{" "}
      </p>
      <br />
        <p>
          <b>Presentation on YouTube:</b><a href="https://youtu.be/DKPIjz3yF74">Link to Predict lottery numbers(Experiment)</a>
        </p>{" "}
        <br />
        <p>
          <b>About the project:&nbsp;&nbsp;&nbsp;</b>My initial AI and Machine Learning project
          using R, many R packages, and by leveraging regression algorithms
        </p>{" "}
        <br />
        <p>
          <b>Concept, Programming and Integration:&nbsp;&nbsp;&nbsp;</b> Muthukumaran Azhagesan,
          Kumar.ALGate@gmail.com
        </p>{" "}
        <br />
        <p>
          <b>Thanks:&nbsp;&nbsp;&nbsp;</b> to mentor Data Scientist Reyhaneh Pahlevan, also to
          Data Scientist Arshu Maskey, and Data Scientist Danny Ma
        </p>{" "}
        <br />
        <p>
          <b>Certificate:&nbsp;&nbsp;&nbsp;</b> can be shown by <a href="https://www.google.com/url?q=https%3A%2F%2Flablab.ai%2Fevent%2Fai-startup-hackathon%2Fyoutube-navigator%2Fyoutube-navigator&sa=D&sntz=1&usg=AOvVaw3iyFJWCmuCQbQBKlwYrXvEhttps://sites.google.com/view/autoshieldsllc/a-i-machine-learning?authuser=0">clicking here.</a>{" "}
        </p>
      </div>
    </div>
    </div>
    </div>
  );
}