import React, { useState } from "react";
import theAIMusicPioneersLogo from './images/The-Music-AI-Pioneers-Logo.png';
export default function MusicAIPioneers() {
    const [collapse, setCollapse] = useState(true);  
    const [expandedDivId, setExpandedDivId] = useState(null);
    const handleDivClick = (divId) => {
      setExpandedDivId(divId === expandedDivId ? null : divId);
    };
    const handleClick = () => {
      setCollapse(!collapse);
    };
  return (
    <div className="flex w-auto h-auto items-center justify-center mx-0.5 bg-white dark:bg-gray-600 dark:text-white rounded-lg overflow-visible shadow-2xl hover:shadow-lg dark:hover:shadow-slate-50 ui-widget-content" onClick={handleClick}>            
    <div className="justify-items-start ui-widget-content">        
          <img 
            className="w-auto h-auto"
            src={theAIMusicPioneersLogo}
            alt="Music AI Pioneers"
          />
      </div>
      <div>
        <br />
        <br />
        <br />
      </div>
      <p className="flex w-15 h-22 items-center mx-0.5 bg-white  dark:bg-gray-600 dark:text-white rounded-lg overflow-visibledark:hover:shadow-slate-50 text-xs text-left ui-widget-content border-none" onClick={handleClick}>The Music AI Pioneers</p>      
      <div className="dark:bg-gray-600 dark:text-white">
      {/* <div className={`div2 dark:bg-gray-600 dark:text-white ui-widget-content ${collapse ? 'collapsed' : ''}`}>                              */}
      <div className={`dark:bg-gray-600 dark:text-white w-${expandedDivId === 'div1' ? 'full' : '3/4'}  ${collapse ? 'collapsed' : ''}`}>        
          <div className="h-7 w-auto">&nbsp;</div>
          <div className="text-justify p-4 text-xs">
          <br />
          <p>
            <b>Objective:&nbsp;&nbsp;&nbsp;</b>Make music leveraging state of the art AI machine learning deep learning tech in a manner the web users desire.{" "}
          </p>
          <p>
            <b>About the project:&nbsp;&nbsp;&nbsp;</b>Leveraging AI, ML, DL to
            produce entertainment in a "You create your own music" way. A team of talented and Enterprise Architects and Software Developers &
            friends @work. Muthukumaran Azhagesan & Rajesh S. Work in progress.
          </p>{" "}
          <p>
            <b>Functionalities:&nbsp;&nbsp;&nbsp;</b>Project planning in progress,
            confidential{" "}
          </p>
          <br/>
        <b>Samples:&nbsp;&nbsp;&nbsp;</b> can be seen by clicking on music player button or menu item in this website or our project image seen above. Note: Leveraged Google's Music AI ML API and recently Facebook/Meta's AI ML API for these music generation.
      </div>
    </div>
    </div>
    </div>
  );
}